import { render, staticRenderFns } from "./menu-bar.vue?vue&type=template&id=1a151aa8&scoped=true&lang=pug"
import script from "./menu-bar.vue?vue&type=script&lang=js"
export * from "./menu-bar.vue?vue&type=script&lang=js"
import style0 from "./menu-bar.vue?vue&type=style&index=0&id=1a151aa8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/.store/vue-loader-virtual-a612f0333f/package/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a151aa8",
  null
  
)

export default component.exports